img.server-icon {
	border: 1px solid black;
	border-radius: 50%;
	max-width: 50%;
}
div.select-wrapper ul.select-dropdown li span {
	color: #0d47a1;
}

div.select-wrapper ul.select-dropdown li.disabled span {
	color: rgba(0, 0, 0, 0.3) !important;
}
div.select-wrapper input.select-dropdown:focus {
	border-bottom-color: #64b5f6;
}